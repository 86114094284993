<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="formData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">
          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
            <el-form-item :prop="'name.'+language" :rules="rules['field']">
              <el-input v-model="formData.name[language]" :placeholder="`Name (${language})`"/>
            </el-form-item>
          </div>

        </div>
        <div class="row g-5">
          <div class="form-check form-check-custom form-check-solid m-3">
            <input class="form-check-input" @change="changeCheckAll" type="checkbox" v-model="checkAll" id="check-all">
            <label class="form-check-label" for="check-all">Check All</label>
          </div>

          <div class="col-md-3" v-for="parentPermission in permissions">
            <div class="card card-stretch shadow mb-5">
              <div class="card-header">

                <h3 class="card-title">{{parentPermission.name}}</h3>
              </div>

              <div class="card-body">
                  <div v-for="permission in parentPermission.children">
                    <div class="form-check form-check-custom form-check-solid m-3">
                      <input class="form-check-input" @change="changeCheckPermission" type="checkbox" v-model="formData.permission_ids" :value="permission.id" :id="'permission-'+permission.id">
                      <label class="form-check-label" :for="'permission-'+permission.id">{{permission.name}}</label>
                    </div>

                  </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row g-9 mb-7">

          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="formData.is_active"/>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useStore} from "vuex";
import {useRoute} from 'vue-router'
import {Delete} from "@element-plus/icons-vue";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import {useRouter} from "vue-router/dist/vue-router";

export default defineComponent({
  name: "role-comp",
  props: ['type'],
  setup(props) {
    const {type} = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    const types = ref([]);
    const languages = ref([]);
    const store = useStore();
    const formRef = ref(null);
    const formData = ref({});
    const loading = ref(null);
    const loadingData = ref(null);
    const permissions = ref([]);
    const encodeJsonFields = ref(['name','permission_ids']);
    const checkAll = ref(false);
    const allPermissionIds = ref([]);

    const isIndeterminate = ref(true)

    formData.value = {
      name: {},
      permission_ids : [],
      is_active: true
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.ROLE.create);
            languages.value = response.data.data.languages;
            permissions.value = response.data.data.permissions;

            setPermissionIds();
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.ROLE.edit(route.params.id));
            formData.value = response.data.data.role;
            languages.value = response.data.data.languages;
            permissions.value = response.data.data.permissions;

            setPermissionIds();
          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.ROLE.show(route.params.id));
            formData.value = response.data.data.role;
            languages.value = response.data.data.languages;
            permissions.value = response.data.data.permissions;

            setPermissionIds();
          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const setPermissionIds = () => {
      let permissionIds = [];
      permissions.value.forEach(function (item){
        permissionIds = permissionIds.concat(item.children.map(a => a.id));
      });
      allPermissionIds.value = permissionIds;
      setCheckedAll();
    }
    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {

      var formDataObj = new FormData();
      Object.keys(formData.value).forEach(function (key) {
        formDataObj.append(key, encodeJsonFields.value.includes(key) ? JSON.stringify(formData.value[key]) : formData.value[key]);
      });

      loading.value = true;
      ApiAxiosService.post(APIs.ROLE.store, formDataObj).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'roles-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {

      var formDataObj = new FormData();
      Object.keys(formData.value).forEach(function (key) {
        formDataObj.append(key, encodeJsonFields.value.includes(key) ? JSON.stringify(formData.value[key]) : formData.value[key]);
      });
      formDataObj.append('_method' , 'put');
      loading.value = true;
      ApiAxiosService.post(APIs.ROLE.update(route.params.id), formDataObj).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'roles-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }

    const changeCheckAll = () => {
      if (checkAll.value) {
        formData.value.permission_ids = allPermissionIds.value;
      } else {
        formData.value.permission_ids = [];
      }
    }
    const changeCheckPermission = () => {
      setCheckedAll();
    }
    const setCheckedAll = () => {
      let permissions = formData.value.permission_ids;
      if(allPermissionIds.value.length == formData.value.permission_ids.length) {
        checkAll.value = true;
      }else {
        checkAll.value = false;
      }
    }

    initData();


    return {
      getIllustrationsPath,
      formRef,
      formData,
      loading,
      loadingData,
      rules,
      languages,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      types,
      isIndeterminate,
      permissions,
      checkAll,
      changeCheckAll,
      changeCheckPermission,

      Delete
    };
  },

});
</script>

